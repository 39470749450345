<template>
  <div>
    <BaseLoading
      style="min-height: 400px; height: 90vh"
      v-if="loading"
    ></BaseLoading>

    <div v-else>
      <BaseHeader :title="`You searched for ${search}`"></BaseHeader>

      <div class="card mt-4 border-0 bg-white shadow">
        <!-- table headers  -->
        <div class="card-body text-right d-flex" style>
          <h3>Orders</h3>
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="$store.state.search"
            append-icon="mdi-magnify"
            label="Search.."
            single-line
            hide-details
            class="my-auto search"
          ></v-text-field>
        </div>

        <!-- // orders table -->
        <v-data-table
          v-if="results.orders && results.orders.length > 0"
          :mobile-breakpoint="0"
          :headers="ordersSearchHeaders"
          :items="results.orders"
          :search="ordersSearch"
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link :to="{ name: 'Order', params: { id: item.id } }">
              <span v-html="item.id_text || item.id"></span>
            </router-link>
          </template>

          <template v-slot:[`item.title`]="{ item }">
            <router-link :to="{ name: 'Order', params: { id: item.id } }">
              <span v-html="item.title"></span>
            </router-link>
          </template>

          <template v-slot:[`item.pages`]="{ item }">
            <span v-html="item.pages"></span>
          </template>

          <template v-slot:[`item.cost`]="{ item }">
            <span v-html="item.cost"></span>
          </template>

          <template v-slot:[`item.speciality`]="{ item }">
            <span v-html="item.speciality"></span>
          </template>

          <template v-slot:[`item.state`]="{ item }">
            <span v-html="item.state"></span>
          </template>

          <template v-slot:[`item.status`]="{ item }">
            <span v-html="item.status"></span>
          </template>

          <template v-slot:[`item.domain`]="{ item }">
            <span v-html="item.domain"></span>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              :to="{ name: 'Order', params: { id: item.id } }"
              fab
              x-small
            >
              <v-icon color="primary" small>mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div v-else>
          <p class="text-center primary--text">
            No orders matching your search query
          </p>
        </div>
      </div>

      <div class="card mt-2 border-0 bg-white shadow">
        <!-- table headers  -->
        <div class="card-body text-right d-flex" style>
          <h3>Users</h3>
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="usersSearch"
            append-icon="mdi-magnify"
            label="Search.."
            single-line
            hide-details
            class="my-auto search"
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->

          <!-- <router-link :to="{ name: 'PlaceOrder' }" class="my-auto ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">  New Order</router-link> -->
        </div>

        <!-- // users table -->
        <v-data-table
          v-if="results.users && results.users.length > 0"
          :mobile-breakpoint="0"
          :headers="usersSearchHeaders"
          :items="results.users"
          :search="$store.state.search"
        >
          <template v-slot:[`item.id`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
            >
              <span v-html="item.id_text || item.id || item.id"></span>
            </router-link>
          </template>
          <template v-slot:[`item.deleted`]="{ item }">
            <v-chip v-if="item.status == '1'" color="primary" small outlined
              >Active</v-chip
            >
            <v-chip v-else color="error" small>Inactive</v-chip>
          </template>
          <template v-slot:[`item.first_name`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
            >
              <span v-html="item.first_name"></span>
            </router-link>
          </template>

          <template v-slot:[`item.last_name`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
            >
              <span v-html="item.last_name"></span>
            </router-link>
          </template>

          <template v-slot:[`item.url`]="{ item }">
            <span v-html="item.url"></span>
          </template>

          <template v-slot:[`item.email`]="{ item }">
            <router-link
              :to="{ name: 'ClientProfile', params: { id: item.id || '' } }"
            >
              <span v-html="item.email"></span>
            </router-link>
          </template>

          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              :to="{ name: 'ClientProfile', params: { id: item.id } }"
              fab
              x-small
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>
        <div v-else>
          <p class="text-center primary--text">
            No users matching your search query
          </p>
        </div>
      </div>

      <div class="card mt-2 border-0 bg-white shadow">
        <!-- table headers  -->
        <div class="card-body text-right d-flex" style>
          <h3>Messages</h3>
          <v-spacer></v-spacer>
          <v-text-field
            dense
            v-model="messagesSearch"
            append-icon="mdi-magnify"
            label="Search.."
            single-line
            hide-details
            class="my-auto search"
          ></v-text-field>
          <!-- <v-spacer></v-spacer> -->

          <!-- <router-link :to="{ name: 'PlaceOrder' }" class="my-auto ml-2 v-btn v-btn--is-elevated v-btn--has-bg theme--light v-size--default primary">  New Order</router-link> -->
        </div>

        <!-- // messages table -->
        <v-data-table
          v-if="results.messages && results.messages.length > 0"
          :mobile-breakpoint="0"
          :headers="messagesSearchHeaders"
          :items="results.messages"
          :search="$store.state.search"
        >
          <template v-slot:[`item.order_id`]="{ item }">
            <router-link
              :to="{ name: 'Order', params: { id: item.order_id } }"
              >{{ item.order_id || "--" }}</router-link
            >
          </template>

          <template v-slot:[`item.message`]="{ item }">
            <span v-html="item.message"></span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-btn
              elevation="0"
              :to="{ name: 'Order', params: { id: item.order_id } }"
              fab
              x-small
            >
              <v-icon color="primary">mdi-eye</v-icon>
            </v-btn>
          </template>
        </v-data-table>

        <div v-else>
          <p class="text-center primary--text">
            No messages matching your search query
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  name: "Search",
  computed: {
    ...mapGetters("auth", ["userType", "userTypes"]),
  },
  data() {
    return {
      loading: true,
      search: "",
      results: {},
      ordersSearch: "",
      usersSearch: "",
      messagesSearch: "",
      ordersSearchHeaders: [
        {
          text: "Id",
          align: "start",
          value: "id",
        },

        { text: "Topic", value: "title", align: "start" },
        { text: "Pages", value: "pages", align: "start" },
        { text: "Order Price", value: "cost", align: "start" },
        { text: "Speciality", value: "speciality", align: "start" },
        { text: "State", value: "state", align: "start" },
        { text: "Status", value: "status", align: "start" },
        { text: "Site", value: "domain", align: "start" },
        { text: "Action", value: "actions", sortable: false, align: "start" },
      ],

      usersSearchHeaders: [
        {
          text: "Id",
          align: "start",
          filterable: false,
          value: "id",
        },
        { text: "First", value: "first_name" },
        { text: "Last", value: "last_name" },
        { text: "Email", value: "email" },
        { text: "Phone", value: "phone" },
        { text: "Site", value: "url" },
        { text: "Action", value: "actions", sortable: false },
      ],

      messagesSearchHeaders: [
        {
          text: "Id",
          align: "start",
          filterable: false,
          value: "id",
        },
        { text: "Order ID", value: "order_id" },
        { text: "Message", value: "message" },
        { text: "Action", value: "actions", sortable: false },
      ],
    };
  },
  mounted() {
    console.log(this.$route.query);
    this.search = this.$route.query.search;
    this.$http
      .post("/System/Search/", { term: this.search })
      .then((res) => {
        this.results = res.data.Data;
        console.log(res);
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        this.loading = false;
      });
  },
};
</script>

<style lang="scss" scoped>
.is-title {
  max-height: 3px !important;
  overflow-y: hidden;
  line-height: 1.5;
  overflow: hidden;
}

.search {
  max-width: 300px;
}
</style>
